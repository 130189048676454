$(document).foundation();

$(document).ready(function() {
	 //initialize swipers when document ready
    var mySwiper = new Swiper ('#folio-swiper', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      navigation: {
	    nextEl: '.swiper-button-next',
	    prevEl: '.swiper-button-prev',
	  },
    });
    var orehSwiper = new Swiper ('.hero-swiper:not(.swiper-single)', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      navigation: {
	    nextEl: '.swiper-arrow-next',
	    prevEl: '.swiper-arrow-prev',
	  },
	  autoplay: {
	    delay: 12000,
	  },
	  speed: 750,
    });
    var dynSwiper = new Swiper ('.dyn-swiper:not(.swiper-single)', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      navigation: {
	    nextEl: '.swiper-arrow-next',
	    prevEl: '.swiper-arrow-prev',
	  },
	  autoplay: {
	    delay: 7000,
	  },
	  speed: 750,
    });
    var dynSwiper = new Swiper ('.vid-swiper:not(.swiper-single)', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      navigation: {
	    nextEl: '.swiper-arrow-next',
	    prevEl: '.swiper-arrow-prev',
	  },
	  speed: 750,
    });
    var varSwiper = new Swiper ('.var-swiper:not(.swiper-single)', {
      // Optional parameters
      slidesPerView: 'auto',
      centeredSlides: false,
      spaceBetween: 8,
      direction: 'horizontal',
      loop: false,
      navigation: {
	    nextEl: '.swiper-arrow-next',
	    prevEl: '.swiper-arrow-prev',
	  },
	  autoplay: false,
	  // speed: 750,
    });


    $('.expander-container').each(function() {
    	var item = $(this).children('.expander');
    	var itemHeight = $(item).css('height');
    	$(item).attr('data-start-height', itemHeight).addClass('collapsed');
    	$(item).attr('data-height', $(item).css('height'));
    });
    $('.expander-container').on('click', '.expander.collapsed', function() {
    	$(this).animate({ height: $(this).attr('data-start-height') }, 'fast').removeClass('collapsed');
    });
    $('.expander-container').on('click', '.expander:not(.collapsed)', function() {
    	// var startHeight = $(this).attr('data-start-height');
    	$(this).animate({ height: $(this).attr('data-height') }, 'fast').addClass('collapsed');
    });
});

$('blockquote:not(.non-quote)').each(function() {
	var quoteText = $(this).html();
	quoteText = '<span class="quote-open">&ldquo;</span>' + quoteText + '<span class="quote-close">&rdquo;</span>';
	$(this).html(quoteText);
});
// $('blockquote').each.text('<span class="quote-open">&ldquo;</span>' + text() + '<span class="quote-close">&rdquo;</span>');

$('#menuTrigger').on('click', function(event) {
	// $('#navMain').toggleClass('open');
	$('#navMain').slideToggle('fast');
});

$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
	switch(newSize) {
	    case 'xlarge':
	        $('#navMain').css('display','block');
	        break;
	    case 'xxlarge':
	        $('#navMain').css('display','block');
	        break;
	    default:
	        // $('#navMain.open').removeClass('open');
	        $('#navMain').css('display','none');
	}  
});

$('#dimDisplay').text($(window).innerWidth());
$(window).on('resize', function() {
	var vWidth = $(window).innerWidth();
	$('#dimDisplay').text(vWidth);
});

$('div.cell[data-url]').on('click', function() {
	var url = $(this).attr('data-url');
	window.location = url;
});


